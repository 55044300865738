import React from 'react';
import './css/styles.css'; // Import your CSS file
import "./css/new.css";
import "./css/styles.css";
import "./css/plugins.css";
import "./quform/css/base.css";
import icon10 from './img/icons/icon-10.png';
import icon11 from './img/icons/icon-11.png';
import icon12 from './img/icons/icon-12.png';


const ProcessStep = ({ icon, number, title, description, delay }) => (
  <div className={`col-md-6 col-lg-4 mt-2-9 wow fadeInUp`} data-wow-delay={delay}>
    <div className="process-style-02 text-center position-relative">
      <div className="process-icon mb-4">
        <img src={icon} alt={title} />
        <span className="process-number">{number}</span>
      </div>
      <h3 className="mb-3 h5">{title}</h3>
      <p className="mb-0 w-lg-85 mx-auto">{description}</p>
    </div>
  </div>
);

const OurProcess = () => (
  <section className="bg-light">
    <div className="container">
      <div className="section-title3 text-center mb-2-9 wow fadeInUp" data-wow-delay="100ms">
        <span className="sm-title">Our Process</span>
        <h2 className="mb-0 h1">Our Process Flow</h2>
      </div>
      <div className="row mt-n2-9">
        <ProcessStep 
          icon={icon10} 
          number="01" 
          title="Choose a Service" 
          description="" 
          delay="100ms" 
        />
        <ProcessStep 
          icon={icon11} 
          number="02" 
          title="Request a Meeting" 
          description="" 
          delay="150ms" 
        />
        <ProcessStep 
          icon={icon12} 
          number="03" 
          title="Receive Custom Plan" 
          description="" 
          delay="200ms" 
        />
      </div>
    </div>
  </section>
);

export default OurProcess;
