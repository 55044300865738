import React, { useState, useEffect } from 'react';

import "./css/services.css";
import "./css/styles.css";
import "./css/plugins.css";
import "./quform/css/base.css";
import { Helmet } from 'react-helmet';

// Import all service images
import image4 from './img/banner/women-in-tech2-1.jpg';
import Home from './img/bg/it-services-banner.webp';
import Healthcare from './img/avatars/Untitled-1200-×-630-px.webp';
import FinancialServices from './img/avatars/Hero+Image+-+Financial+Services.jpg';
import RetailE from './img/avatars/1610731293666.jpeg';
import Education from './img/avatars/education-is-important.jpg';
import Manufacturing from './img/avatars/im-hero-2020.avif';
import Transportation from './img/avatars/logistic.avif';
import Real from './img/avatars/THE-ROLE-OF-A-REAL-ESTATE-AGENCY-IN-SPAIN-scaled.jpg';
import Telecommunications from './img/avatars/Global-telecom-promo.jpeg';
import Energy from './img/avatars/Energy & utility_Thumbnil-Banner.jpg';
import Hospitality from './img/avatars/travel-scaled.webp';
import Government from './img/avatars/public-sector-transformation-government-departments-rely-upon-legacy-systems-e1630574780447 - Copy.jpeg';
import Media from './img/avatars/75690061.cms';
import Automotive from './img/avatars/AdobeStock_305628569-1 - Copy.jpeg';
import Agriculture from './img/avatars/f3b719e9-e3c6-4851-8d64-8f7e7dfaac88_1792x1024 - Copy.avif';

const Industry = () => {
  const [activeService, setActiveService] = useState('');

  useEffect(() => {
    // Get URL parameters
    const params = new URLSearchParams(window.location.search);
    const serviceFromUrl = params.get('service');

    // Set default service to 'Healthcare' if no service is specified in the URL
    if (serviceFromUrl && serviceDetails[serviceFromUrl]) {
      setActiveService(serviceFromUrl);
    } else {
      setActiveService('Healthcare');  // Default to Healthcare if no URL parameter
    }
  }, []);

  const handleClick = (service) => {
    setActiveService(service);
  };

 const serviceDetails = {
  'Healthcare': {
    paragraph: 'Managing patient information more effectively.',
    list: [
      'Electronic Health Records (EHR): Managing patient information more effectively.',
      'Telemedicine: Facilitating remote consultations for better healthcare access.',
      'Health Information Management: Organizing and managing medical data.',
      'Clinical Decision Support: Assisting healthcare professionals in making informed decisions.',
      'Medical Device Integration: Connecting medical devices with software for better data handling.'
    ],
    image: Healthcare,
  },
  'Financial Services': {
    paragraph: 'Managing banking operations and customer services.',
    list: [
      'Banking Solutions: Managing banking operations and customer services.',
      'Investment Management: Tools for tracking and managing investments.',
      'Payment Processing: Secure systems for handling transactions.',
      'Risk & Compliance: Managing financial risks and meeting regulatory requirements.',
      'Insurance Management: Handling insurance policies and claims.'
    ],
    image: FinancialServices,
  },
  'Retail & E-Commerce': {
    paragraph: 'Custom platforms for your e-commerce needs.',
    list: [
      'Online Stores: Custom platforms for your e-commerce needs.',
      'Inventory Management: Keeping track of stock levels and managing inventory.',
      'CRM: Managing customer relationships and enhancing engagement.',
      'Point of Sale (POS): Systems for processing sales transactions.',
      'Supply Chain Management: Optimizing the flow of goods from suppliers to customers.'
    ],
    image: RetailE,
  },
  'Education': {
    paragraph: 'Platforms for delivering and managing educational content.',
    list: [
      'Learning Management Systems (LMS): Platforms for delivering and managing educational content.',
      'Online Courses: Solutions for creating and managing online learning experiences.',
      'Student Information Systems (SIS): Managing student records and academic information.',
      'Virtual Classrooms: Technology for remote teaching and interactive learning.',
      'Education Analytics: Improving teaching and learning outcomes with insights and reports.'
    ],
    image: Education,
  },
  'Manufacturing': {
    paragraph: 'Managing and monitoring manufacturing processes.',
    list: [
      'Manufacturing Execution Systems (MES): Managing and monitoring manufacturing processes.',
      'Supply Chain Management: Optimizing the flow of materials and products.',
      'Industrial Automation: Automating manufacturing processes to improve efficiency.',
      'Production Planning: Scheduling and planning production activities.',
      'Quality Management: Ensuring product quality and compliance.'
    ],
    image: Manufacturing,
  },
  'Transportation & Logistics': {
    paragraph: 'Managing and optimizing vehicle fleets.',
    list: [
      'Fleet Management: Managing and optimizing vehicle fleets.',
      'Transportation Management: Planning and executing transportation operations.',
      'Route Optimization: Finding the most efficient delivery routes.',
      'Warehouse Management: Managing inventory and operations in warehouses.',
      'Supply Chain Analytics: Improving logistics and supply chain efficiency with insights.'
    ],
    image: Transportation,
  },
  'Real Estate & Property Management': {
    paragraph: 'Managing rental properties and tenants.',
    list: [
      'Property Management Systems: Managing rental properties and tenants.',
      'Real Estate CRM: Handling real estate transactions and client relationships.',
      'Property Listings: Showcasing and searching real estate properties.',
      'Lease Management: Managing lease agreements and renewals.',
      'Facility Management: Maintaining and managing property facilities.'
    ],
    image: Real,
  },
  'Telecommunications': {
    paragraph: 'Monitoring and managing telecommunications networks.',
    list: [
      'Network Management: Monitoring and managing telecommunications networks.',
      'Customer Service Platforms: Handling customer inquiries and support.',
      'Billing Systems: Managing customer billing and payments.',
      'Telecom Analytics: Insights into network performance and customer behavior.',
      'VoIP Solutions: Technology for voice over IP communications.'
    ],
    image: Telecommunications,
  },
  'Energy & Utilities': {
    paragraph: 'Monitoring and optimizing energy usage.',
    list: [
      'Energy Management: Monitoring and optimizing energy usage.',
      'Smart Grids: Modernizing electrical grids for better efficiency.',
      'Utility Billing: Managing billing and customer accounts for utilities.',
      'Renewable Energy Integration: Incorporating renewable energy sources.',
      'Asset Management: Managing and maintaining energy and utility assets.'
    ],
    image: Energy,
  },
  'Hospitality & Travel': {
    paragraph: 'Managing hotel operations and guest services.',
    list: [
      'Hotel Management Systems: Managing hotel operations and guest services.',
      'Booking Platforms: Making and managing travel bookings and reservations.',
      'Customer Experience: Enhancing guest satisfaction and loyalty.',
      'Travel Management: Handling travel itineraries and arrangements.',
      'Activity Booking: Booking tours, activities, and experiences.'
    ],
    image: Hospitality,
  },
  'Government & Public Sector': {
    paragraph: 'Delivering government services online.',
    list: [
      'E-Government Solutions: Delivering government services online.',
      'Public Safety: Managing emergency response and public safety operations.',
      'Citizen Engagement: Improving communication and interaction with citizens.',
      'Regulatory Compliance: Meeting government regulations and standards.',
      'Public Health Systems: Managing public health data and initiatives.'
    ],
    image: Government,
  },

  'Media & Entertainment': {
    paragraph: 'Organizing and distributing media content.',
    list: [
      'Content Management: Organizing and distributing media content.',
      'Streaming Solutions: Delivering media and entertainment content online.',
      'Digital Rights Management (DRM): Protecting digital content from unauthorized use.',
      'Audience Analytics: Understanding audience behavior and preferences.',
      'Booking Systems: Managing event bookings and ticket sales.'
    ],
    image: Media,
  },

  'Automotive': {
    paragraph: 'Innovative solutions for the automotive industry.',
    list: [
      'Connected Cars: Solutions for vehicle-to-everything (V2X) communication.',
      'Autonomous Vehicles: Technology for self-driving cars.',
      'Vehicle Telematics: Remote monitoring and diagnostics for vehicles.',
      'Manufacturing Automation: Streamlining vehicle production.',
      'Supply Chain Management: Managing the automotive supply chain.'
    ],
    image: Automotive,
  },

  'Agriculture': {
    paragraph: 'Technology-driven solutions for modern agriculture.',
    list: [
      'Precision Farming: Using data and technology to optimize crop yields.',
      'Farm Management Systems: Organizing farm operations and resources.',
      'IoT in Agriculture: Monitoring crop and livestock conditions in real-time.',
      'Smart Irrigation: Efficiently managing water resources for crops.',
      'Agricultural Analytics: Data-driven insights for better decision-making.'
    ],
    image: Agriculture,
  }
};

  return (
    <>
     <Helmet>
     <title> Industry-Specific Software Solutions | Code Crafters HQ</title>
        <meta
          name="description"
          content=" Solutions for industry-specific software are provided by 
Code Crafters HQ. Our areas of expertise include manufacturing, retail, healthcare, and many 
more"
        /> </Helmet>
      <section 
        className="page-title-section bg-img cover-background mx-lg-1-6 mx-xl-2-5 mx-xxl-2-9 " 
        data-overlay-dark="65" 
        style={{ backgroundImage: `url(${Home})` }}
      >
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="position-relative">
                <h1 style={{ textAlign: 'center', fontSize: '40px',marginTop:'100px', marginBottom:'100px' }}>Industries We Serve
                </h1>
              </div>
            </div>
          </div>
        </div>


      </section>

      {/* CONTENT MANAGEMENT */}
      <section>
        <div className="container">
          <div className="row">
            <div className="col-lg-4 order-2 order-lg-1">
              <div className="sidebar me-xxl-1-9">
                <div className="mb-2-9 bg-light border-radius-10 pt-4 px-4 pb-1-9">
                  <div className="mb-1-9">
                    <h3 className="text-primary h4">Our Services</h3>
                  </div>
                  <ul className="cetegory p-0 list-unstyled mb-0">
                    {Object.keys(serviceDetails).map(service => (
                      <li key={service} className={`mb-2 ${activeService === service ? 'active' : ''}`}>
                        <a href="#!" onClick={() => handleClick(service)}>
                          <span>{service.replace(/-/g, ' ').toUpperCase()}</span>
                        </a>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>

            <div className="col-lg-8 mb-2-9 mb-xl-0 order-1 order-lg-2">
              <img
                src={activeService ? serviceDetails[activeService].image : 'default-image.jpg'} 
                className="mb-1-9 wow fadeIn" 
                data-wow-delay="200ms" 
                alt={activeService || 'All Services'}
              />
              <div className="wow fadeIn" data-wow-delay="200ms">
                <h2 className="mb-3 h3">{activeService ? activeService.replace(/-/g, ' ').toUpperCase() : 'Our Services'}</h2>
                
                {activeService && (
                  <>
                    <p className="mb-1-9">{serviceDetails[activeService].paragraph}</p>
                    <ul style={{ listStyleType: 'disc', paddingLeft: '20px' }}>
                      {serviceDetails[activeService].list.map((item, index) => (
                        <li key={index}>{item}</li>
                      ))}
                    </ul>
                  </>
                )}

                {!activeService && (
                  <p className="mb-1-9">
                    Please select a service to see the details.
                  </p>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="hero-section"  style={{ 
  backgroundImage: `url(${image4})`,
    
  }}>
            <div className="overlay">
                <div className="content">
                    <h1 style={{ color: '#fff' }} >Join Us on Our Journey</h1>
                    <p>At Code Crafters HQ, we’re not just creating software; we’re crafting the future. Whether you need a new application, want to modernize an existing system, or need expert advice, we’re here to help. Let’s work together to turn your vision into reality!</p>
                    <p>Thank you for visiting our site. We’re excited about the possibility of working with you!</p>
                    <a href="#contact" className="contact-button">Contact Us</a>
                </div>
            </div>
        </section>
     
;    </>
  );
};

export default Industry;
