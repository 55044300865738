import React, { useState, useEffect } from 'react';
import './css/styles.css'; // Import your CSS file
import "./css/new1.css";
import "./css/styles.css";
import "./css/plugins.css";
import "./quform/css/base.css";
import service1 from './img/service/service-02.jpg';
import service2 from './img/service/service-03.jpg';
import service3 from './img/service/service-04.jpg';
import service4 from './img/service/service-05.jpg';
import service5 from './img/service/service-06.jpg';
import service6 from './img/service/service-07.jpg';
import icon1 from './img/icons/icon-28.png';
import icon2 from './img/icons/icon-29.png';
import icon3 from './img/icons/icon-30.png';
import icon4 from './img/icons/icon-31.png';
import icon5 from './img/icons/icon-32.png';
import icon6 from './img/icons/icon-33.png';

// Data array with imported images and icons
const servicesData = [
  {
    title: 'Custom Software Development',
    img: service1,
    icon: icon1,
    link: 'oursoftwaresolutions',
    description: 'Creating software that’s uniquely yours.',
  },
  {
    title: 'Application Modernization',
    img: service2,
    icon: icon2,
    link: 'oursoftwaresolutions',
    description: 'Upgrading old systems to keep you ahead of the curve.',
  },
  {
    title: 'Cloud Solutions',
    img: service3,
    icon: icon3,
    link: 'oursoftwaresolutions',
    description: 'Moving your business to the cloud for more flexibility',
  },
  {
    title: 'Integration Services',
    img: service4,
    icon: icon4,
    link: 'oursoftwaresolutions',
    description: ' Connecting different systems and services seamlessly',
  },
  {
    title: ' UX/UI Design',
    img: service5,
    icon: icon5,
    link: 'oursoftwaresolutions',
    description: ' Designing engaging and intuitive user experiences',
  },
  {
    title: 'Maintenance & Support',
    img: service6,
    icon: icon6,
    link: 'oursoftwaresolutions',
    description: 'Keeping your software up-to-date and running smoothly.',
  },
];

// Individual Service Card Component
const ServiceCard = ({ service }) => (
  <div className="card card-style-10 bg-transparent border-0">
    <div className="card-body p-0 bg-transparent">
      <div className="card-front" style={{ backgroundImage: `url(${service.img})` }}>
        <div className="card-butn"><span>{service.title}</span></div>
      </div>
      <div className="card-back">
        <img src={service.icon} alt={service.title} className="mb-3 mx-auto w-60px" />
        <h3 className="h4 mb-3">
          <a href={service.link} className="text-white">{service.title}</a>
        </h3>
        <p className="mb-0 text-white">{service.description}</p>
      </div>
    </div>
  </div>
);

// Main Services Section Component
const Services = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const itemsToShow = 3;

  const nextSlide = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex + itemsToShow >= servicesData.length
        ? 0
        : prevIndex + itemsToShow
    );
  };

  const prevSlide = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex - itemsToShow < 0
        ? servicesData.length - itemsToShow
        : prevIndex - itemsToShow
    );
  };

  useEffect(() => {
    const interval = setInterval(nextSlide, 3000);
    return () => clearInterval(interval);
  }, [currentIndex]);

  return (
    <section className="services-section">
      <div className="container">
        {/* Title and Subtitle */}
  <h2 style={{ textAlign: 'center', fontSize: '45px', color: 'white' }}>What We Offer
  </h2>

       
        <div className="services-container">
          <div className="services-wrapper" style={{ transform: `translateX(-${currentIndex * (100 / itemsToShow)}%)` }}>
            {servicesData.map((service, index) => (
              <ServiceCard key={index} service={service} />
            ))}
          </div>
          <button className="prev-button" onClick={prevSlide}>❮</button>
          <button className="next-button" onClick={nextSlide}>❯</button>
        </div>
      </div>

      <style jsx>{`
  /* Mobile (show 1 card per row) */
  @media (max-width: 767px) {
    .card {
      flex: 0 0 100%; /* Show one card per row */
    }
  }

  /* Tablet (show 2 cards per row) */
  @media (min-width: 768px) {
    .card {
      flex: 0 0 50%; /* Show two cards per row */
    }
  }

  /* Desktop (show 3 cards per row) */
  @media (min-width: 1024px) {
    .card {
      flex: 0 0 33.33%; /* Show three cards per row */
    }
  }
`}</style>
    </section>
  );
};

export default Services;
