import React, { useState, useEffect } from "react";
import "./slider.css";
import slide1 from './img/banner/page-title.jpg';
import slide2 from './img/banner/slide-02.jpg';
import slide3 from './img/bg/bg-04.jpg';

const Slider = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const slides = [
    {
      title: "Welcome to Code Crafters HQ",
      text: "At Code Crafters HQ, we’re not just building software; we’re crafting solutions that are as unique as your business. Our team, made up of nine talented freelancers with diverse skills and backgrounds,is dedicated to turning your ideas into reality with custom software designed just for you.",
      backgroundImage: `url(${slide1})`,
    },
    {
     title: "Welcome to Code Crafters HQ",
      text: "At Code Crafters HQ, we’re not just building software; we’re crafting solutions that are as unique as your business. Our team, made up of nine talented freelancers with diverse skills and backgrounds,is dedicated to turning your ideas into reality with custom software designed just for you.",
      backgroundImage: `url(${slide2})`,
    },
    {
     title: "Welcome to Code Crafters HQ",
      text: "At Code Crafters HQ, we’re not just building software; we’re crafting solutions that are as unique as your business. Our team, made up of nine talented freelancers with diverse skills and backgrounds,is dedicated to turning your ideas into reality with custom software designed just for you.",
      backgroundImage: `url(${slide3})`,
    },
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentSlide((prev) => (prev === slides.length - 1 ? 0 : prev + 1));
    }, 3000);

    return () => clearInterval(interval);
  }, [slides.length]);

  return (
    <div className="slider">
      <div
        className="slider-container"
        style={{ transform: `translateX(-${currentSlide * 100}%)` }}
      >
        {slides.map((slide, index) => (
          <div
            className="slide"
            key={index}
            style={{ backgroundImage: slide.backgroundImage }}
          >
            <div className="slide-content" style={{ backgroundColor: 'rgba(255, 255, 255, 0.1)', padding: '20px' }}>
              <h1 style={{ color: '#e74860' }}>{slide.title}</h1>
              <p>{slide.text}</p>
            </div>
          </div>
        ))}
      </div>
      <div className="controls">
        {slides.map((_, index) => (
          <button
            key={index}
            className={currentSlide === index ? "active" : ""}
            onClick={() => setCurrentSlide(index)}
          ></button>
        ))}
      </div>
    </div>
  );
};

export default Slider;
