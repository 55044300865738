import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import './whatsapp1.css'; // Ensure you have this CSS file

const WhatsAppButton = () => {
  return (
    <a 
      href="https://api.whatsapp.com/send?phone=17542093936" 
      className="float" 
      target="_blank" 
      rel="noopener noreferrer"
    >
      <FontAwesomeIcon icon={faWhatsapp} className="my-float" />
    </a>
  );
};

export default WhatsAppButton;
