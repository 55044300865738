import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './pages/home';
import About from './pages/about';
import Contact from './pages/contact';
import Solutions from './pages/oursoftwaresolutions';
import Industry from './pages/Industriesweserve';
import Header from './components/header';
import Footer from './components/footer';
import ScrollToTop from './components/ScrollToTop';  // Import ScrollToTop component
import './App.css';

const App = () => {
  return (
    <Router basename="/">
      <ScrollToTop /> {/* This will trigger the scroll-to-top behavior */}
      <div>
        <Header />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/oursoftwaresolutions" element={<Solutions />} />
          <Route path="/Industriesweserve" element={<Industry />} />
        </Routes>
        <Footer />
      </div>
    </Router>
  );
};

export default App;
