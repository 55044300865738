import React from 'react';
import "./css/new.css";
import "./css/styles.css";
import "./css/plugins.css";
import image1 from './img/content/about3.jpg';
import image2 from './img/content/about4.jpg';
import image3 from './img/content/about5.jpg';

const AboutUs = () => {
  return (
    <section className="pb-lg-18 overflow-visible about-style01">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-7 col-xl-6 mb-6 mb-sm-7 mb-lg-0">
            <div className="position-relative text-sm-start">
              <img src={image1} className="mb-sm-15" alt="About Us 1" />
              <img src={image2} className="position-absolute bottom-0 end-0 bg-white p-2 d-none d-sm-block" alt="About Us 2" />
              <img src={image3} className="position-absolute top-5 right-5 d-none d-sm-block" alt="About Us 3" />
              <div className="bg-white border-primary border border-width-3 p-4 about-quote ani-left-right d-none d-sm-block">
                <p className="mb-0 text-dark">"If you want real marketing that works and effective effect - Startup got you covered."
                  <span className="fw-bold"> - Angelina May</span>
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-5 col-xl-6">
            <div className="ps-xl-7">
              <div className="section-title2 text-start mb-1-9 wow fadeInUp" data-wow-delay="100ms">
                
                <h2 className="h1 mb-0" style={{  fontSize: '28px', }}>Who We Are
                </h2>
              </div>
              <p className="mb-1-9 font-weight-600 lead wow fadeInUp" data-wow-delay="150ms" style={{  fontSize: '14px', }}>
              Welcome to Code Crafters HQ! We’re a group of nine passionate freelancers who came together with 
a shared goal: to build custom software solutions that truly meet your needs. Each of us brings a 
unique set of skills and experiences, allowing us to tackle a wide range of projects with creativity and 
precision
              </p>
              
              <div className="section-title2 text-start mb-1-9 wow fadeInUp" data-wow-delay="100ms">
                
                <h2 className="h1 mb-0" style={{  fontSize: '28px', }} >Our Story

                </h2>
              </div>
              <p className="mb-1-9 font-weight-600 lead wow fadeInUp" data-wow-delay="150ms" style={{  fontSize: '14px', }}>
              Our journey began with a simple idea: exceptional software should be crafted to fit each client’s 
unique needs. We united our diverse expertise to form a company that values collaboration, 
innovation, and client satisfaction above all else.

              </p>
 
              
              <div>
                <a href="/about" className="butn-style2 medium wow fadeInUp" data-wow-delay="200ms">
                  <span className="text-white">Read More</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="d-inline-block p-3 bg-primary rounded-circle position-absolute right-10 top-10 ani-move d-md-block d-none" style={{ 
    width: '30px', height:'30px'  }}></div>
    </section>
  );
};

export default AboutUs;
