const servicesData = [
  {
    title: 'Collaboration',
    description: 'We believe in working closely with you and each other to achieve the best results.',
    link: 'information-security.html',
  },
  {
    title: 'Innovation',
    description: 'We’re always exploring new technologies and ideas to bring you the best solutions.',
    link: 'mobile-platforms.html',
  },
  {
    title: 'Integrity',
    description: 'Honesty and transparency guide everything we do.',
    link: 'data-synchronization.html',
  },
  {
    title: 'Excellence',
    description: 'We’re committed to delivering top-notch software and services, every time.',
    link: 'process-automation.html',
  },
];

const Value = () => {
  return (
    <section className="bg-light">
      <div className="container">
        <div className="section-title3 text-center mb-1-9 wow fadeInUp" data-wow-delay="100ms">
          <h2 className="mb-0 h1">Our Values</h2>
        </div>
        <div className="row">
          {servicesData.slice(0, 4).map((service, index) => (
            <div className="col-md-6 col-lg-3" key={index}>
              <div className="card card-style13">
                <div className="card-body">
                  <h3 className="h4 mb-3">
                    <a href={service.link}>{service.title}</a>
                  </h3>
                  <p className="mb-4">{service.description}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <style jsx>{`
        @media (min-width: 768px) and (max-width: 991px) {
          .col-md-6 {
            flex: 0 0 50%;
            max-width: 50%;
          }
        }
      `}</style>
    </section>
  );
};


export default Value;
