import React from 'react';
import "./css/new.css";
import "./css/styles.css";
import "./css/plugins.css";
import aboutImage from './img/content/about9.jpg'; // Adjusted to use import
import icon1 from './img/icons/icon-35.png'; // Adjusted to use import
import icon2 from './img/icons/icon-36.png'; // Adjusted to use import

const Why = () => {
  return (
        <section className="about-style-04" style={{ marginTop: '10px', marginBottom: '10px' }}>

      <div className="container">
        <div className="row mt-n2-9">
          <div className="col-lg-6 mt-2-9 wow fadeIn" data-wow-delay="200ms">
            <div className="pe-xl-5">
              <div className="about-image position-relative">
                <img src={aboutImage} alt="About" className="img-fluid" /> {/* Added `className` for better styling */}
                <div className="about-img-text row g-0">
                  <div className="col-sm-6">
                    <div className="d-flex p-3 p-xxl-4 h-100 bg-secondary">
                      <div className="flex-shrink-0">
                        <img src={icon1} alt="Design Staff Icon" className="w-50px" />
                      </div>
                      <div className="flex-grow-1 ms-3">
                        <h3 className="mb-0 text-white">
                          <span className="countup">100</span>+
                        </h3>
                        <p className="mb-0 text-white opacity9">Design Staff</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="d-flex p-3 p-xxl-4 h-100 bg-primary">
                      <div className="flex-shrink-0">
                        <img src={icon2} alt="Project Completed Icon" className="w-50px" />
                      </div>
                      <div className="flex-grow-1 ms-3">
                        <h3 className="mb-0 text-white">
                          <span className="countup">200</span>+
                        </h3>
                        <p className="mb-0 text-white opacity9">Project Completed</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-6 mt-2-9">
            <div className="ps-lg-1-9 ps-xl-0">
              <div className="section-title2 text-start mb-1-9">
                
                <h2 className="h1 mb-0">Why Choose Us?
                </h2>
              </div>
              {/* <p className="mb-2-5 wow fadeInUp" data-wow-delay="200ms">
                We’re a top-notch Award-Winning Agency. We are experienced in providing innovative creative services to
                our clients worldwide.
              </p> */}
              <div className="steps-item-wrap wow fadeInUp" data-wow-delay="300ms">
                <div className="steps-wrap">
                  <div className="steps-number">
                    <span className="text-white">01</span>
                  </div>
                  <div className="steps-line-wrap">
                    <div className="steps-line"></div>
                  </div>
                </div>
                <div className="steps-content">
                  <h4>Tailor-Made Solutions</h4>
                  <p className="mb-0 w-lg-95">
                   We understand that every business is different. That’s why we create 
                  software that fits your needs perfectly, rather than offering a one-size-fits-all solution </p>
                </div>
              </div>
              <div className="steps-item-wrap wow fadeInUp" data-wow-delay="400ms">
                <div className="steps-wrap">
                  <div className="steps-number">
                    <span className="text-white">02</span>
                  </div>
                  <div className="steps-line-wrap">
                    <div className="steps-line"></div>
                  </div>
                </div>
                <div className="steps-content">
                  <h4> Expert Team</h4>
                  <p className="mb-0 w-lg-95">
                  Our team brings a wealth of knowledge from various tech fields and industries, 
                  ensuring we have the right expertise to tackle your project, no matter how complex.
                  </p>
                </div>
              </div>
              <div className="steps-item-wrap wow fadeInUp" data-wow-delay="300ms">
                <div className="steps-wrap">
                  <div className="steps-number">
                    <span className="text-white">03</span>
                  </div>
                  <div className="steps-line-wrap">
                    <div className="steps-line"></div>
                  </div>
                </div>
                <div className="steps-content">
                  <h4>Agile Approach</h4>
                  <p className="mb-0 w-lg-95">
                  We use agile methods to keep things flexible and transparent. This means 
                  you’re always in the loop and can make adjustments as needed.
                 </p></div>
              </div>
 
            </div>
            <div className="steps-item-wrap wow fadeInUp" data-wow-delay="500ms">
                <div className="steps-wrap">
                  <div className="steps-number">
                    <span className="text-white">04</span>
                  </div>
                  <div className="steps-line-wrap">
                    <div className="steps-line "></div>
                  </div>
                </div>
                <div className="steps-content mb-0">
                  <h4>Full Support</h4>
                  <p className="mb-0 w-lg-95">
                   From the initial idea to the final product and beyond, we provide end-to-end 
                  service to make sure everything runs smoothly  </p>
                </div>
              </div>
             
          </div>
        </div>
      </div>
    </section>
  );
};

export default Why;
