import React from 'react';
import { Link } from 'react-router-dom';
import '@fortawesome/fontawesome-free/css/all.min.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faLocation, faMapMarkerAlt, faMobileAlt } from '@fortawesome/free-solid-svg-icons';
import footer1 from './imgpsh_fullsize_anim.png';
import WhatsAppButton from './whatsapp';
import './header.css';

import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
const Footer = () => {
  return (
    <footer className="footer-style1 bg-dark pt-6 pt-lg-8">
      <div className="container">
        <div className="row pb-6 pb-lg-8 mt-n5">
          <div className="col-lg-4 mt-5">
            <div className="footer-logo mb-1-9">
              <a href="/">
                <img src={footer1} alt="Footer Logo" style={{ height: '50px', width: '100px', objectFit: 'cover' }}/>
              </a>
            </div>
            <p className="mb-1-9 text-white">
              We have 14+ years experience. Helping you overcome technology challenges.
            </p>
            <ul className="social-icons2 list-unstyled mb-0">
              <li>
                <a href="#!">
                  <i className="fab fa-facebook-f"></i>
                </a>
              </li>
              <li>
                <a href="#!">
                  <i className="fab fa-twitter"></i>
                </a>
              </li>
              <li>
                <a href="#!">
                  <i className="fab fa-instagram"></i>
                </a>
              </li>
              <li>
                <a href="#!">
                  <i className="fab fa-linkedin-in"></i>
                </a>
              </li>
            </ul>
          </div>

          <div className="col-lg-8 mt-5">
  <div className="row">
    <div className="col-12 mb-1-9 pb-4 border-bottom border-color-light-white">
      <div className="row mt-n4">
        <div className="col-md-6 col-lg-4 mt-4">
          <div className="d-flex">
            <div className="flex-shrink-0 me-3">
            <a href="tel:+911203185939" target="_blank" rel="noopener noreferrer">
              <FontAwesomeIcon icon={faMobileAlt} style={{ color: 'rgb(231, 72, 96)', fontSize: '20px' }} />
       </a>
            </div>
            <div className="flex-grow-1">
              <h4 className="h5 text-white mb-1">Phone</h4>
              <a href="tel:+911203185939" className="mb-0 text-white opacity8 small">
              +91-120-3185939
              </a>
            </div>
          </div>
        </div>
   

                  <div className="col-md-6 col-lg-4 mt-4">
  <div className="d-flex">
    <div className="flex-shrink-0 me-3">
      <a href="https://wa.me/17542093936" target="_blank" rel="noopener noreferrer">
        <FontAwesomeIcon icon={faWhatsapp} style={{ color: 'rgb(231, 72, 96)', fontSize: '20px' }} />
      </a>
    </div>
    <div className="flex-grow-1">
      <h4 className="h5 text-white mb-1">Whatsapp</h4>
      <p className="mb-0 text-white opacity8 small">
        <a href="https://wa.me/17542093936" target="_blank" rel="noopener noreferrer" className="text-white">
          +1 754-209-3936
        </a>
      </p>
    </div>
  </div>
</div>
<div className="col-md-6 col-lg-4 mt-4">
  <div className="d-flex">
    <a href="mailto:nirajp@codecraftershq.com" className="flex-shrink-0 me-3">
      <FontAwesomeIcon icon={faEnvelope} style={{ color: 'rgb(231, 72, 96)', fontSize: '20px' }} />
    </a>
    <div className="flex-grow-1">
      <h4 className="h5 text-white mb-1">Email</h4>
      <a href="mailto:sales@codecraftershq.com" className="mb-0 text-white opacity8 small">
        sales@codecraftershq.com
      </a>
    </div>
  </div>
</div>

                </div>
              </div>

              <div className="col-sm-6 col-md-4 mb-2-9 mb-md-0">
  <h3>About Us</h3>
  <ul className="ps-0 mb-0">
    <li><Link to="/">Home</Link></li>
    <li><Link to="/about">About</Link></li>
    <li><Link to="/contact">Contact</Link></li>
  </ul>
</div>

<div className="col-sm-6 col-md-4 mb-2-6 mb-md-0">
  <h3>Quick Link</h3>
  <ul className="ps-0 mb-0">
    <li><Link to="/oursoftwaresolutions">Our Software Solutions</Link></li>
    <li><Link to="/industriesweserve">Industries We Serve</Link></li>
  </ul>
</div>


              
<div className="col-md-6 col-lg-4" style={{ marginTop: '-7px' }}>

              <h3 >Location</h3>
                    <div className="d-flex">
                    <div className="flex-shrink-0 me-3">
                      <FontAwesomeIcon icon={faLocation} style={{ color: 'rgb(231, 72, 96)', fontSize: '20px' }} />
                      </div>
                   
                      <div className="flex-grow-1">
                      
                    
                        <p className="mb-0 text-white opacity8 small">
                      Niti Khand-1, Indirapuram, Ghaziabad-201014

                        </p>
                      </div>
                    </div>
                  </div>
                  </div>
                  </div>

                      
          
        </div>
      </div>

      <div className="py-4 border-top border-color-light-white">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-12 text-center">
              <p className="d-inline-block text-white mb-0">
                &copy; <span className="current-year"></span> Copyright{'Codecraftershq '}All rights reserved
             
              </p>
            </div>
            <WhatsAppButton /> {/* Add WhatsApp Button */}
          </div>
        </div>
        
      </div>
          </footer>
    
  );
};

export default Footer;
