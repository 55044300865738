import React, { useState, useEffect } from 'react';
import {  HelmetProvider } from 'react-helmet-async';


import "./css/services.css";
import "./css/styles.css";
import "./css/plugins.css";
import "./quform/css/base.css";

// Import all service images
import service1 from './img/banner/10-techniques-for-developing-an-effective-app-modernization-strategy.jpg';
import service2 from './img/banner/pfv1zMs.jpeg';
import applicationModernization from './img/banner/0_yIIe3Zwmnx0KLsBe.jpg';
import cloudSolutions from './img/banner/Cloud-Based-Solutions-1280x960-1.jpeg';
import integrationServices from './img/banner/integration.webp';
import uxUiDesign from './img/banner/2-5.webp';
import maintenanceSupport from './img/banner/website-maintenance-support-service.jpg';
import dataSolutions from './img/banner/1583198230878.jfif';
import devOpsSolutions from './img/banner/Software-Development-Team (1).jpg';
import securitySolutions from './img/banner/1557740513813.jfif';
import businessIntelligence from './img/banner/background-images_www-3.webp';
import eCommerceSolutions from './img/banner/e-commerce-solutions-toronto.jpg';
import consultingStrategy from './img/banner/filters_format(jpeg).jfif';
import { Helmet } from 'react-helmet';

const Solutions = () => {
  
    const [activeService, setActiveService] = useState('');
  
    useEffect(() => {
      // Get URL parameters
      const params = new URLSearchParams(window.location.search);
      const serviceFromUrl = params.get('service');
  
      // Set default service to 'Healthcare' if no service is specified in the URL
      if (serviceFromUrl && serviceDetails[serviceFromUrl]) {
        setActiveService(serviceFromUrl);
      } else {
        setActiveService('Custom Software Development');  // Default to Healthcare if no URL parameter
      }
    }, []);
  

  const handleClick = (service) => {
    setActiveService(service);
  };

  // Define descriptions, lists, and images for each service
  const serviceDetails = {
    'Custom Software Development': {
      paragraph: 'Custom Software Development involves the creation of tailored solutions that meet specific needs of a business.',
       list: [
    'Tailor-Made Applications: We create software that’s perfectly suited to your business needs.',
    'Enterprise Solutions: Powerful tools designed for large organizations to streamline operations.',
    'Desktop Apps: Custom software for your computer, whether you’re on Windows, macOS, or Linux.',
    'Mobile Apps: Engaging apps for smartphones and tablets, available for iOS and Android.',
    ' Web Apps: Interactive and user-friendly applications accessible from any web browser.',
  
  ],
      image: service2,
    },
    'Application Modernization': {
      paragraph: 'Application Modernization helps in upgrading legacy applications to align with current business needs.',
      list: [
    
   
    'Revamping Legacy Systems: Updating your old systems to improve performance and capabilities.',
    ' Cloud Migration: Moving your applications and data to the cloud for greater efficiency.',
    'Microservices: Breaking down large applications into smaller, manageable pieces for easier maintenance.'
  ],
      image: applicationModernization,
    },
    'Cloud Solutions': {
      paragraph: 'Cloud Solutions offer flexibility, scalability, and ease of management for businesses of all sizes.',
      list: [ ' Cloud-Native Apps: Software designed to run seamlessly in the cloud.',
    'Cloud Integration: Connecting your existing systems with cloud services for a smooth operation.',
    'Cloud Management: Handling your cloud infrastructure so you don’t have to.'],
      image: cloudSolutions,
    },
    'Integration Services': {
      paragraph: 'Integration Services ensure that different software applications can work together seamlessly.',
      list: ['Real-time data synchronization', 'Seamless API integration', 'Improved business processes'],
      image: integrationServices,
    },
    'UX/UI Design': {
      paragraph: 'UX/UI Design focuses on creating a user-friendly interface that enhances customer satisfaction.',
      list: [ 'User Experience (UX) Design: Designing software that’s easy and enjoyable to use.',
    'User Interface (UI) Design: Crafting beautiful and functional interfaces for your applications.',
    'Prototypes & Wireframes: Building visual drafts to help you see and test ideas before development starts.',],
      image: uxUiDesign,
    },
    'Maintenance & Support': {
      paragraph: 'Our Maintenance & Support services ensure that your applications run smoothly with minimal downtime.',
      list: ['Ongoing Software Maintenance: Regular updates and fixes to keep your software running smoothly.',
    ' Technical Support: Helping you with any issues and ensuring your software performs well.',
    ' Performance Monitoring: Keeping track of how your software runs and addressing any problems that come up.',],
      image: maintenanceSupport,
    },
    'Data Solutions': {
      paragraph: 'Data Solutions help businesses manage and leverage their data for better decision-making.',
      list: ['Database Management: Designing and managing databases to keep your data organized and accessible.',
    ' Data Analytics & Reporting: Turning data into insights with custom reports and analysis.',
    ' Big Data Solutions: Handling and analyzing large sets of data to find valuable insights.',],
      image: dataSolutions,
    },
    'DevOps Solutions': {
      paragraph: 'DevOps Solutions bridge the gap between development and operations for faster, more reliable software delivery.',
      list: ['CI/CD Pipelines: Automating the testing and deployment of your software for quicker, more reliable updates.',
    'Infrastructure Automation: Streamlining the setup and management of your infrastructure.',
    'Monitoring & Logging: Keeping an eye on your software’s performance to prevent issues before they arise.',],
      image: devOpsSolutions,
    },
    'Security Solutions': {
      paragraph: 'Security Solutions protect your digital assets from cyber threats and ensure regulatory compliance.',
      list: ['Application Security: Protecting your software from vulnerabilities and cyber threats.',
    'Data Encryption: Securing your sensitive data with advanced encryption techniques.',
    ' Compliance Solutions: Ensuring your software meets all necessary regulations and standards.',],
      image: securitySolutions,
    },
    'Business Intelligence': {
      paragraph: 'Business Intelligence (BI) solutions help organizations make data-driven decisions.',
      list: [' Dashboard Development: Creating interactive dashboards to visualize important data and metrics.',
    ' Data Warehousing: Storing and managing large volumes of data from various sources.',
    ' Advanced Analytics: Using advanced techniques like machine learning to get deeper insights from your data.',],
      image: businessIntelligence,
    },
    'E-Commerce Solutions': {
      paragraph: 'E-Commerce Solutions help businesses build robust online stores with seamless shopping experiences.',
      list: [ 'Custom Online Stores: Building e-commerce platforms tailored to your business.',
    ' Payment Gateway Integration: Integrating secure payment systems for smooth transactions.',
    ' Shopping Cart Development: Creating shopping cart functionality for a seamless online shopping experience.',],
      image: eCommerceSolutions,
    },
    'Consulting & Strategy': {
      paragraph: 'Our Consulting & Strategy services provide expert advice to help businesses navigate complex challenges.',
      list: ['Technology Consulting: Offering expert advice on technology strategies to help your business grow.',
    'Digital Transformation: Helping you adopt digital technologies to improve your operations and customer experience.',
    ' IT Strategy Development: Crafting IT strategies that align with your business goals.',],
      image: consultingStrategy,
    }
  };

  return (
    <>
     <Helmet>
       <title>Custom Software Solutions for Businesses | Code Crafters HQ</title>
        <meta
          name="description"
          content=" Code Crafters HQ offers custom software solutions for 
businesses. We specialize in cloud solutions, UX/UI design, e-commerce solutions, and many 
more"
        />   </Helmet>

      <section 
        className="page-title-section bg-img cover-background mx-lg-1-6 mx-xl-2-5 mx-xxl-2-9 " 
        data-overlay-dark="65" 
        style={{ backgroundImage: `url(${service1})` }}
      >
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="position-relative">
                <h1 style={{ textAlign: 'center', fontSize: '40px',marginBottom:'100px', marginTop:'100px', }}>Our Software Solutions</h1>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* CONTENT MANAGEMENT */}
      <section>
        <div className="container">
          <div className="row">
            <div className="col-lg-4 order-2 order-lg-1">
              <div className="sidebar me-xxl-1-9">
                <div className="mb-2-9 bg-light border-radius-10 pt-4 px-4 pb-1-9">
                  <div className="mb-1-9">
                    <h3 className="text-primary h4">Our Software Solutions</h3>
                  </div>
                  <ul className="cetegory p-0 list-unstyled mb-0">
                    {Object.keys(serviceDetails).map(service => (
                      <li key={service} className={`mb-2 ${activeService === service ? 'active' : ''}`}>
                        <a href="#!" onClick={() => handleClick(service)}>
                          <span>{service.replace(/-/g, ' ').toUpperCase()}</span>
                        </a>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>

            <div className="col-lg-8 mb-2-9 mb-xl-0 order-1 order-lg-2">
              <img
                src={activeService ? serviceDetails[activeService].image : 'default-image.jpg'} 
                className="mb-1-9 wow fadeIn" 
                data-wow-delay="200ms" 
                alt={activeService || 'All Services'}
              />
              <div className="wow fadeIn" data-wow-delay="200ms">
                <h2 className="mb-3 h3">{activeService ? activeService.replace(/-/g, ' ').toUpperCase() : 'Our Services'}</h2>
                
                {activeService && (
                  <>
                    <p className="mb-1-9">{serviceDetails[activeService].paragraph}</p>
                    <ul style={{ listStyleType: 'disc', paddingLeft: '20px' }}>
                      {serviceDetails[activeService].list.map((item, index) => (
                        <li key={index}>{item}</li>
                      ))}
                    </ul>
                  </>
                )}

                {!activeService && (
                  <p className="mb-1-9">
                    Please select a service to see the details.
                  </p>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Solutions;
